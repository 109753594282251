<template>
  <b-modal id="view-detail-affiliate-dashboard" size="lg" no-close-on-backdrop no-close-on-esc hide-footer centered>
    <template #modal-header="{ close }">
      <div class="text-secondary text-bold">{{$t('affiliate.detail.detail')}}</div>
      <div class="d-flex">
        <div class="mr-3 d-flex">
          <div class="mr-2 text-secondary text-bold">{{$t('affiliate.detail.date')}}</div>
          <div>: {{ changeDateFormat(date) }}</div>
        </div>
        <i class="fas fa-times hover-icon action-button" @click="closePopup"/>
      </div>
    </template>
    <div>
      <div class="d-flex">
        <div class="mr-3">
          {{$t('affiliate.detail.customer_list')}}
        </div>
        <div>
          : <strong>{{changeFormatNumber(total)}}</strong> {{$t('affiliate.detail.person')}}
        </div>
      </div>
      <div>
        <b-row>
          <b-col lg="3" md="3" sm="4">
            <b-form-group
                label-cols="12"
                :label="$t('affiliate.detail.name')"
                label-for="input-sm"
            >
              <b-form-input v-model="form.name"/>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" sm="4">
            <b-form-group
                label-cols="12"
                :label="$t('affiliate.detail.email')"
                label-for="input-sm"
            >
              <b-form-input v-model="form.email"/>
            </b-form-group>
          </b-col>
          <b-col style="margin-top: 35px">
            <button
                type="button"
                class="btn waves-effect waves-light btn-gradient"
                @click="getDashboardDetail(customer_id)"
            >
              {{ $t("common.Search") }}
            </button>
            <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
              {{ $t('common.resetSearch') }}
            </button>
          </b-col>
        </b-row>
        <div>
          <b-row>
            <b-table
                responsive
                class="mb-0"
                :items="item"
                :fields="fields"
                :busy="isBusy"
                :empty-text="$t('empty')"
                show-empty
                foot-clone
            >
              <template #head()="{ label, field: { key, sortable }}">
                {{ $t(label) }}
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong> {{ $t('loading') }}</strong>
                </div>
              </template>
              <template #cell(customer_name)="data">
                <div>
                  {{ data.item.customer_name }}
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div>
                  {{ data.item.customer_email }}
                </div>
              </template>
              <template #cell(total_affiliate_amount)="data">
                <div class="text-primary">
                  {{ changeFormatNumberToPrice(data.item.total_affiliate_amount) }}
                </div>
              </template>
              <template #cell(total_price_exclude_vat)="data">
                <div>
                  {{ changeFormatNumberToPrice(data.item.total_price_exclude_vat) }}
                </div>
              </template>
              <template #foot(customer_name)="data">
                <span class="foot-text">{{ $t('affiliate.detail.sum') }}</span>
              </template>
              <template #foot(total_affiliate_amount)="data">
                <span>{{ changeFormatNumberToPrice(sum_grand) }}</span>
              </template>
              <template #foot(customer_email)="data">
                <span class="text-danger">{{ '' }}</span>
              </template>
              <template #foot(total_price_exclude_vat)="data">
                <span class="text-secondary">{{ changeFormatNumberToPrice(sum_commission) }}</span>
              </template>
            </b-table>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import affiliateApi from "@/repository/affiliateApi";
import functionCommon from "@/common/functions"

export default {
  name: "viewDetailAffiliate",
  props: ['customer_id', 'date'],
  data() {
    return {
      fields: [
        {
          key: "customer_name",
          label: "affiliate.detail.name",
        },
        {
          key: "customer_email",
          label: "affiliate.detail.email",
        },
        {
          key: "total_price_exclude_vat",
          label: "affiliate.detail.total_affiliate_amount",
        },
        {
          key: "total_affiliate_amount",
          label: "affiliate.detail.total_price_exclude_vat",
        },
      ],
      isBusy: false,
      total_rows: 1,
      pageOptions: [10, 15, 50, 100],
      item: [],
      form: {
        name: '',
        email: ''
      },
      total: 0,
      sum_grand: 0,
      sum_commission: 0
    }
  },
  methods: {
    getDashboardDetail(id) {
      const payload = {
        customer_id: id,
        date: functionCommon.changeDateFormatValueInput(this.date),
        name: this.form.name,
        email: this.form.email
      }
      this.isBusy = true
      affiliateApi.getAffiliateDashboardDetail(payload).then(resp => {
        if (!!resp) {
          this.item = resp.data
          this.total = resp.total
          this.sum_grand = _.sumBy(resp.data, 'total_affiliate_amount')
          this.sum_commission = _.sumBy(resp.data, 'total_price_exclude_vat')
        }
      }).catch(err => {
        throw err
      }).finally(() => {
        this.isBusy = false
      })
    },
    changeDateFormat(date) {
      return functionCommon.changeDateNotTimeFormat(date)
    },
    closePopup () {
      this.form = {
        name: '',
        email: ''
      }
      this.$emit('update', true)
      this.$bvModal.hide('view-detail-affiliate-dashboard')
    },
    changeFormatNumberToPrice(number) {
      return '฿' +  functionCommon.addComma(number) || 0
    },
    changeFormatNumber(number) {
      return functionCommon.addComma(number) || 0
    },
    resetSearch () {
      this.form = {
        name: '',
        email: ''
      }
      this.getDashboardDetail(this.customer_id)
    }
  },
  watch: {
    'customer_id'(val) {
      if (val) {
        this.getDashboardDetail(val)
      }
    }
  }
}
</script>
<style scoped>

</style>