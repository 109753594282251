<template>
  <div v-if="checkPermission('list', 'action', 'affiliate')">
    <div>
      <div class="main-container-url">
        <div class="box-topic">
          {{ $t('affiliate.dashboard.overflow') }}
        </div>
        <div class="box-url">
          <b-row align-h="center">
            <b-col cols="3" class="text-right">
              <div class="mt-2 text-bold">
                {{ $t('affiliate.dashboard.url_link') }}
              </div>
            </b-col>
            <b-col class="text-left">
              <input-component input-id="url-link-input" :value="member_info.affiliate_full_link"
                               min-height="2em" disabled-type class-name="url-link"/>
            </b-col>
            <b-col cols="5" class="text-left">
              <div class="d-flex" style="gap: 8px">
                <b-button class="ml-2" variant="primary" @click="copyText">
                  <i class="fas fa-copy"/>
                  {{ $t('affiliate.dashboard.copy') }}
                </b-button>
                <b-button class="ml-1 d-flex align-items-center" style="gap: 4px" variant="primary"
                          @click="openQRModal(member_info.affiliate_full_link)"
                >
                  <img src="@/assets/images/icon/qrcode-icon.svg" class="mb-1">
                  {{ `${$t('common.download')} QR Code` }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="main-container-dashboard">
        <div class="box-total-commission">
          <div>
            <div>{{ $t('affiliate.dashboard.commission_total') }}</div>
            <h2 class="content-sum">
              {{ changeFormatNumberToPrice(summary_data_box.commission_total) }}
            </h2>
          </div>
        </div>
        <div class="box-total-commission-detail">
          <div>{{ $t('affiliate.dashboard.grand_total') }}</div>
          <h2>
            {{ changeFormatNumberToPrice(summary_data_box.grand_total) }}
          </h2>
        </div>
        <div class="box-total-commission-detail">
          <div>{{ $t('affiliate.dashboard.total_click') }}</div>
          <h2>
            {{ changeFormatNumber(summary_data_box.total_click) }}
          </h2>
        </div>
        <div class="box-total-commission-detail split">
          <div>
            <div>{{ $t('affiliate.dashboard.registered_total') }}</div>
            <h3>
              {{ changeFormatNumber(summary_data_box.total_register) }}
            </h3>
          </div>
          <div class="small-container">
            <div class="right-line">
              <div>{{ $t('affiliate.dashboard.subscription_success_total') }}</div>
              <h5>
                {{ changeFormatNumber(summary_data_box.total_member) }}
              </h5>
            </div>
            <!-- <div>
              <div>{{ $t('affiliate.dashboard.subscription_failed_total') }}</div>
              <h5>
                {{ changeFormatNumber(summary_data_box.total_register - summary_data_box.total_member) }}
              </h5>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <b-card>
        <div>
          <h4>{{ $t('affiliate.dashboard.daily_report') }}</h4>
          <b-row>
            <b-col lg="3" md="3" sm="4">
              <b-form-group label-cols="12"
                            :label="$t('common.dateTime')" label-for="input-sm">
                <date-time-range-component v-model="time" type="date"/>
              </b-form-group>
            </b-col>
            <b-col style="margin-top: 35px">
              <button
                  type="button"
                  class="btn waves-effect waves-light btn-gradient"
                  @click="getDashboardAffiliateList()"
              >
                {{ $t("common.Search") }}
              </button>
              <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch()">
                {{ $t('common.resetSearch') }}
              </button>
            </b-col>
          </b-row>
        </div>
        <hr/>
        <div>
          <b-row>
            <b-col lg="3" md="3" sm="4">
              <div class="commission-box">
                <div>{{ $t('affiliate.dashboard.commission') }}</div>
                <h2>
                  {{ changeFormatNumberToPrice(summary_data_table.commission_total) || 0 }}
                </h2>
              </div>
            </b-col>
            <b-col lg="3" md="3" sm="4">
              <div class="commission-box-gray">
                <div>{{ $t('affiliate.dashboard.grand_total') }}</div>
                <h2>
                  {{ changeFormatNumberToPrice(summary_data_table.grand_total) || 0 }}
                </h2>
              </div>
            </b-col>
            <b-col lg="3" md="3" sm="4">
              <div class="commission-box-gray">
                <div>{{ $t('affiliate.dashboard.total_click') }}</div>
                <h2>
                  {{ changeFormatNumber(summary_data_table.total_click) || 0 }}
                </h2>
              </div>
            </b-col>
            <b-col lg="3" md="3" sm="4">
              <div class="commission-box-gray split">
                <div>
                  <div>{{ $t('affiliate.dashboard.registered_total') }}</div>
                  <h3>
                    {{ changeFormatNumber(summary_data_table.total_register) || 0 }}
                  </h3>
                </div>
                <div class="small-container">
                  <div class="right-line">
                    <div>{{ $t('affiliate.dashboard.subscription_success_total') }}</div>
                    <h5>
                      {{ changeFormatNumber(summary_data_table.total_member) }}
                    </h5>
                  </div>
                  <!-- <div>
                    <div>{{ $t('affiliate.dashboard.subscription_failed_total') }}</div>
                    <h5>
                      {{ changeFormatNumber(summary_data_table.total_register - summary_data_table.total_member) }}
                    </h5>
                  </div> -->
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-5">
          <b-row align-v="center" class="pb-3">
            <b-col>
              <div class="d-flex align-items-center">
                <span class="mr-3">{{ $t('common.perPage') }}</span>
                <div>
                  <b-form-select v-model="form.$limit"
                                 :options="pageOptions"
                                 @change="getDashboardAffiliateList"
                  />
                </div>
                <div class="ml-3">
                  <total-list-component :total="0"/>
                </div>
              </div>
            </b-col>
            <!--            <b-col lg="4" md="4" sm="4" align="end">-->
            <!--              <b-button variant="dark" class="mr-1">-->
            <!--                <i class="fas fa-file-excel "></i>-->
            <!--                {{ $t("button.export") }}-->
            <!--              </b-button>-->
            <!--            </b-col>-->
          </b-row>
          <div>
            <b-row>
              <b-table
                  responsive
                  class="mb-0"
                  :items="item"
                  :fields="fields"
                  :busy="isBusy"
                  :empty-text="$t('empty')"
                  show-empty
              >
                <template #head()="{ label, field: { key, sortable }}">
                  {{ $t(label) }}
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong> {{ $t('loading') }}</strong>
                  </div>
                </template>
                <template #cell(createdAt)="data">
                  <div>
                    {{ changeDateFormat(data.item.createdAt) }}
                  </div>
                </template>
                <template #cell(total_register)="data">
                  <div>
                    {{ changeFormatNumber(data.item.total_register) }}
                  </div>
                </template>
                <template #cell(total_click)="data">
                  <div>
                    {{ changeFormatNumber(data.item.total_click) }}
                  </div>
                </template>
                <template #cell(total_member)="data">
                  <div>
                    {{ changeFormatNumber(data.item.total_member) }}
                  </div>
                </template>

                <template #cell(grand_total)="data">
                  <div>
                    {{ changeFormatNumberToPrice(data.item.grand_total) }}
                  </div>
                </template>
                <template #cell(commission_total)="data">
                  <div class="text-secondary text-bold">
                    {{ changeFormatNumberToPrice(data.item.commission_total) }}
                  </div>
                </template>
                <template #cell(action)="data">
                  <i style="color: #0d73d9" class="action-button icon-eye " @click="viewDetail(data.item)"/>
                </template>
              </b-table>
              <div class="ml-auto">
                <b-pagination
                    v-model="form.$skip"
                    :total-rows="totalRows"
                    :per-page="form.$limit"
                    align="fill"
                    class="my-0"
                    size="sm"
                    @input="getDashboardAffiliateList"
                ></b-pagination>
              </div>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
    <div>
      <view-detail-affiliate :customer_id="customer_id" :date="date" @update="closePopup()"/>
      <b-modal id="view-detail-affiliate-qr" size="md" no-close-on-backdrop no-close-on-esc hide-footer centered>
        <template #modal-header="{ close }">
          <h4 class="text-secondary text-bold m-0">{{ `${$t('common.download')} QR Code` }}</h4>
          <div class="d-flex">
            <i class="fas fa-times hover-icon action-button" @click="closePopupQR"/>
          </div>
        </template>
        <div class="d-flex align-items-center justify-content-center" style="padding: 18px">
          <div class="d-flex flex-column align-items-center" style="gap: 12px">
            <div class="d-flex align-items-center" style="gap: 4px">
              <img v-show="BrandSetting.image.logo"
                   :src="BrandSetting.image.logo"
                   class="layout-img mb-2"
                   alt="logo" height="32px"
              />
              <img v-show="BrandSetting.image.logo"
                   :src="BrandSetting.image.text_logo"
                   class="layout-img mb-2"
                   alt="logo" height="32px"
              />
            </div>

            <div class="d-flex flex-column align-items-center primary-border" style="gap: 4px">
              <canvas ref="canvas" class="border-bottom-primary"></canvas>

            </div>
            <div style="width: 100%; margin-top: 16px">

              <a :href="qrCodeDataUrl" :download="downloadFilename" v-if="qrCodeDataUrl">
                <b-button class="d-flex align-items-center justify-content-center" style="gap: 8px; width: 100%" variant="primary"
                >
                  <img src="@/assets/images/icon/download.svg" width="14px">
                  {{ `${$t('common.download')} QR Code` }}
                </b-button>
              </a>
            </div>
          </div>
        </div>
      </b-modal>

    </div>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponent.vue";
import SelectFilterComponent from "@/components/common/selectFilterComponent.vue";
import TotalListComponent from "@/components/common/totalList.vue";
import EmptyTable from "@/components/common/emptyTable.vue";
import ViewDetailAffiliate from "@/views/affiliate/dashboard/viewDetail.vue";
import affiliateApi from "@/repository/affiliateApi";
import functionCommon from "@/common/functions"
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent.vue";
import moment from "moment";
import functions from "@/common/functions";
import QRCode from 'qrcode';


export default {
  name: "index",
  components: {
    DateTimeRangeComponent,
    ViewDetailAffiliate, EmptyTable, TotalListComponent, SelectFilterComponent, InputComponent
  },
  data() {
    return {
      text: '',
      qrCodeDataUrl: null,
      downloadFilename: 'affiliate-qr.png',
      fields: [
        {
          key: "createdAt",
          label: "affiliate.list.date",
        },
        {
          key: "total_click",
          label: "affiliate.list.total_click",
          class: 'text-center'
        },
        {
          key: "total_register",
          label: "affiliate.dashboard.registered_total",
          class: 'text-center'
        },
        {
          key: "total_member",
          label: "affiliate.dashboard.subscription_success_total",
          class: 'text-center'
        },
        {
          key: "grand_total",
          label: "affiliate.list.grand_total",
          class: 'text-center'
        },
        {
          key: "commission_total",
          label: "affiliate.list.commission_total",
          class: 'text-center'
        },
        {
          key: "action",
          label: "affiliate.list.detail",
          class: 'text-center'
        }
      ],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 50, 100],
      item: [],
      summary_data_table: {
        commission_total: 0,
        grand_total: 0,
        total_click: 0,
        total_member: 0,
        total_register: 0
      },
      summary_data_box: {
        commission_total: 0,
        grand_total: 0,
        total_click: 0,
        total_member: 0,
        total_register: 0
      },
      time: [],
      form: {
        date_from: null,
        date_to: null,
        $skip: 1,
        $limit: 10,
      },
      dashboard_detail: {
        date: null,
        data: []
      },
      customer_id: "",
      date: "",
      member_info: {}
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters["profile/getProfile"] || JSON.parse(localStorage.getItem('profile'))
    },
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    }
  },
  mounted() {
    // get session reload page
    const reload = sessionStorage.getItem('reload_page')
    if (reload == 'true') {
      sessionStorage.removeItem('reload_page')
      setTimeout(() => {
        location.reload();
      }, 100)
    }

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    this.time = [
      new Date(startOfMonth + 'T00:00:00'),
      new Date(endOfMonth + 'T23:59:59')
    ]

    this.getSummaryBox()
    this.getMemberAffiliateDetail()
  },
  methods: {
    closePopupQR() {
      this.text = ''
      this.qrCodeDataUrl = null
      this.downloadFilename = 'affiliate-qr.png'
      this.$bvModal.hide('view-detail-affiliate-qr')
    },
    openQRModal(link) {
      this.text = link
      this.$bvModal.show('view-detail-affiliate-qr')
      setTimeout(() => {
        this.generateQrCode()

      }, 100)
    },
    generateQrCode() {
      const canvas = this.$refs.canvas;
      QRCode.toCanvas(canvas, this.text, {width: 200, margin: 2}, (error) => {
        if (error) console.error(error);
        this.qrCodeDataUrl = canvas.toDataURL('image/png');
      });
    },
    viewDetail(data) {
      this.customer_id = data.customer_id
      this.date = data.createdAt
      if (this.customer_id) {
        this.$bvModal.show('view-detail-affiliate-dashboard')
      }
    },
    getSummaryBox() {
      const data = {
        customer_id: this.userInfo?._id,
      }
      affiliateApi.getSummaryBoxDashboard(data).then(resp => {
        if (!!resp) {
          this.summary_data_box = {
            ...this.summary_data_box,
            commission_total: resp?.commission_total,
            grand_total: resp?.grand_total,
            total_click: resp?.total_click,
            total_member: resp?.total_member,
            total_register: resp?.total_register,
          }
        }
      }).then((resp) => {
        this.getDashboardAffiliateList()
      }).catch(err => {
        throw err
      })
    },
    getDashboardAffiliateList() {
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      const data = {
        customer_id: this.userInfo?._id,
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        $skip: this.form.$skip,
        $limit: this.form.$limit
      }
      this.isBusy = true
      affiliateApi.getAffiliateDashboardList(data).then(resp => {
        if (!!resp) {
          const summary_data = _.get(resp, 'summaryData', {})
          this.item = resp?.data
          this.totalRows = resp?.total
          this.summary_data_table = {
            ...this.summary_data_table,
            commission_total: summary_data?.commission_total || 0,
            grand_total: summary_data?.grand_total || 0,
            total_click: summary_data?.total_click || 0,
            total_member: summary_data?.total_member || 0,
            total_register: summary_data?.total_register || 0,
          }
        }
      }).catch(err => {
        throw err
      }).finally(() => {
        this.isBusy = false
      })
    },
    resetSearch() {
      this.form = {
        date_from: null,
        date_to: null,
        $skip: 1,
        $limit: 10,
      }
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.time = [
        new Date(startOfMonth + 'T00:00:00'),
        new Date(endOfMonth + 'T23:59:59')
      ]
      this.getSummaryBox()
    },
    changeDateFormat(date) {
      return functionCommon.changeDateNotTimeFormat(date)
    },
    copyText() {
      const textInput = document.getElementById('url-link-input').value;
      navigator.clipboard.writeText(textInput).then(() => {
        this.$bvToast.toast(`Copied successfully`, {
          title: 'Copy',
          autoHideDelay: 5000,
        })
      }).catch(err => {
        console.error('Error copying text: ', err);
      });
    },
    closePopup() {
      this.customer_id = ""
      this.date = ""
    },
    getMemberAffiliateDetail() {
      affiliateApi.getMemberDetailAffiliate().then(resp => {
        if (!!resp) {
          this.member_info = resp
        }
      }).catch(err => {
        throw err
      })
    },
    changeFormatNumberToPrice(number) {
      return '฿' + functionCommon.addComma(number) || 0
    },
    changeFormatNumber(number) {
      return functionCommon.addComma(number) || 0
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  }
}
</script>

<style lang="scss">
#view-detail-affiliate-qr {
  .modal-header {
    border-bottom: 1px solid #E9E9E9;
  }
}

.primary-border {
  border-radius: 12px;
  padding: 8px;
  border: 1px solid var(--primary-color);
  position: relative;
}

.url-link:disabled {
  background: rgba(202, 255, 243, 1) !important;
  color: #5C698C !important;
  font-style: italic !important;
}

.main-container-dashboard {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  .box-total-commission {
    height: 158px;
    border-radius: 10px;
    border: 1px solid #0EB99B;
    background: #19CBAC;
    color: #FFFFFF;
    padding: 2.5rem 1rem 1rem 1rem;

    .content-sum {
      color: #FFF968;
      font-size: 40px;
    }
  }

  .box-total-commission-detail {
    height: 158px;
    border-radius: 10px;
    border: 1px solid #DDEAF8;
    color: #5C698C;
    background: #FFFFFF;
    padding: 3rem 1rem 1rem 1rem;
  }
  .box-total-commission-detail.split {
    padding: 1.2rem 1rem 1rem 1rem;
  }
  .small-container {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    margin-top: 0.25rem;
    padding-top: 0.75rem;
    border-top: 1px solid #DDEAF8;
    gap: 0.5rem;
  }
  // .right-line {
  //   border-right: 1px solid #DDEAF8;
  // }
}

.main-container-url {
  display: grid;
  grid-template-columns: 2fr 3.12fr;
  margin-top: 1rem;
  gap: 1rem;

  .box-topic {
    background: #FFFFFF;
    height: 74px;
    border-radius: 10px;
    color: var(--secondary-color);
    vertical-align: middle;
    padding: 1.5rem;
    font-size: 18px;
    font-weight: bold;
  }

  .box-url {
    background: #FFFFFF;
    height: 74px;
    border-radius: 10px;
    padding: 1.2rem 1rem 1rem 1rem;
    vertical-align: middle;
  }
  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
}

.commission-box {
  border-radius: 10px;
  color: var(--primary-color);
  border: 1px solid #0EB99B;
  min-height: 130px;
  padding: 2rem 1rem 2rem 1rem;
}

.commission-box-gray {
  border-radius: 10px;
  color: #5C698C;
  border: 1px solid #DDEAF8;
  min-height: 130px;
  padding: 2rem 1rem 2rem 1rem;
  
}
.commission-box-gray.split {
  padding: 0.4rem 1rem 0.4rem 1rem;
  .small-container {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    margin-top: 0;
    padding-top: 0.4rem;
    border-top: 1px solid #DDEAF8;
    gap: 0.5rem;
  }
  // .right-line {
  //   border-right: 1px solid #DDEAF8;
  // }
}


</style>